<template>
  <v-container>
    <ProductionOrders
      :productionOrders="productionOrders"
      :products="products"
    />
    <v-tabs
      v-if="false"
    >
      <v-tab>Ordenes de producción</v-tab>

      <v-tab-item>
        <ProductionOrders
          :productionOrders="productionOrders"
          :products="products"
        />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import firebaseApi from '../api/firebaseApi'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import ProductionOrders from '@/components/ProductionOrders.vue'
import redaApp from '../api/redaApp'

export default {
  name: 'Production',

  components: {
    ProductionOrders
  },

  data () {
    return {
      e1: 1,
      loading: false,
      products: [],
      listener: null,
      productionOrders: []
      // Aquí es donde se declararían las variables de tu componente
    }
  },

  computed: {
    ...mapGetters('company', ['getCompany'])
  },

  methods: {
    async getProducts () {
      this.products = []
      this.productsIndexed = {}
      this.loading = true
      try {
        const querySnapshot = await firebaseApi.db.collection('products')
          .where('company', '==', this.getCompany.identification).get()
        for (let i = 0; i < querySnapshot.docs.length; i++) {
          const doc = querySnapshot.docs[i]
          const product = { ...doc.data(), uid: doc.id }
          this.products.push(product)
          this.productsIndexed[product.commercialCode] = product
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async reset () {
      this.loading = true
      if (this.listener) this.listener()
      this.listener = firebaseApi.db.collection('productionOrders')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot(({ docs }) => {
          this.productionOrders = []
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            const productionOrder = doc.data()
            const product = this.productsIndexed[productionOrder.product]
            if (productionOrder.task) {
              redaApp.getTask(productionOrder.task)
                .then((task) => {
                  productionOrder.task = task
                  const i = task.childs
                    .findIndex(({ status }) => status === '03')
                  productionOrder.nextStep = task.childs[i + 1] || null
                  productionOrder.currStep = task.childs[i] || task
                })
                .catch((e) => console.error(e))
            }
            productionOrder.commercialCode = product.commercialCode
            productionOrder.product = product
            productionOrder.uid = doc.id
            this.productionOrders.push(productionOrder)
          }
          this.loading = false
        })
    },
    ...mapMutations([]),
    ...mapActions([])
  },
  mounted: async function () {
    await this.getProducts()
    this.reset()
  },
  beforeDestroy: function () {
    this.listener()
    console.log('beforeDestroy')
  }
}
</script>
