<template>
  <v-data-table
    :headers="headers"
    :items="getItems"
    class="elevation-1"
    :loading="loading"
    :search="search"
    dense
    >
    <template  v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Inventario</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Dividir inventario</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="9">
                    <v-select
                      :items="cellarItemsExit"
                      label="Sale de"
                      v-model="editedItem.exit"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field type="number" v-model="editedItem.exitQuantity" label="Cantidad"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      label="Producto entrante"
                      :items="getItems"
                      v-model="editedItem.entryProduct"
                      itemText="product.description"
                      itemValue="code"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="9">
                    <v-select
                      :items="cellarItems"
                      label="Entra a"
                      v-model="editedItem.entry"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field type="number" v-model="editedItem.entryQuantity" label="Cantidad"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" @click="save">Registrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          class="mt-3 py-2 mr-2"
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.cost="{ item }">
      {{item.cost | currencyFormat}}
    </template>
    <template v-slot:item.value="{ item }">
      {{item.value | currencyFormat}}
    </template>
    <template v-slot:item.currentvalue="{ item }">
      {{item.currentvalue | currencyFormat}}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="!(item.stock > 0) ? 'grey' : 'secondary'"
            dark
            v-bind="attrs"
            v-on="on"
            @click="splitItem(item)"
            icon
            :loading="loading"
            :disabled="!(item.stock > 0)"
          >
            <v-icon>
              {{ 'mdi-swap-horizontal' }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ 'Dividir producto' }}</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { /* mapState, /**/mapActions, mapGetters } from 'vuex'
import tools from '@/api/tools.js'
import firebaseApi from '../api/firebaseApi'
export default {
  name: 'Stock',
  data () {
    return {
      dialog: false,
      search: '',
      loading: false,
      listenerProducts: null,
      headers: [
        {
          text: 'Código',
          align: 'start',
          value: 'code'
        },
        { text: 'Producto', value: 'product.description' },
        { text: 'En bodega', value: 'stock', align: 'end' },
        { text: 'Costo unitario', value: 'cost', align: 'end' },
        { text: 'Valor actual', value: 'currentvalue', align: 'end' },
        { text: '', value: 'actions' }
      ],
      editedItem: {
        exitProduct: null,
        exit: null,
        exitQuantity: 0,
        entryProduct: null,
        entry: null,
        entryQuantity: 0
      },
      defaultItem: {
        exitProduct: null,
        exit: null,
        exitQuantity: 0,
        entryProduct: null,
        entry: null,
        entryQuantity: 0
      }
    }
  },
  mounted: function () {
  },
  beforeDestroy: function () {
  },
  props: {
    products: Array
  },
  methods: {
    splitItem (item) {
      this.editedItem.exitProduct = { ...item }
      this.dialog = true
    },
    reset () {
      if (this.listenerProducts) this.listenerProducts()
      this.products = []
      // redaApp.importAttachmentsVouchers().then(() => console.log('Importing from email')).catch((e) => console.error(e))
      this.listenerProducts = firebaseApi.db.collection('products')
        .where('company', '==', this.getCompany.identification)
        .where('inventoryable', '==', true)
        .onSnapshot(({ docs }) => {
          this.loading = true
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            this.products.push({ ...doc.data(), uid: doc.id })
          }
          this.loading = false
        })
    },
    round (number) {
      return tools.round10(number, -2)
    },
    customFilter (item, queryText /* itemText /**/) {
      const textOne = item.product?.description?.toLowerCase()
      const textTwo = item.code.toString().toLowerCase()
      const searchText = queryText.toString().toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
      textTwo.indexOf(searchText) > -1
    },
    close () {
      this.editedItem = { ...this.defaultItem }
      this.dialog = false
    },
    save () {
      const stack = []
      const inventoryExit = {}
      // inventory.id = this.inventories.length + 1
      inventoryExit.date = new Date().getTime()
      inventoryExit.company = this.getCompany.identification
      inventoryExit.product = this.editedItem.exitProduct.code
      // inventoryExit.productFull = this.getProductTmp
      inventoryExit.detail = 'SALIDA POR FABRICACION'
      inventoryExit.entry = null
      inventoryExit.exit = this.editedItem.exit
      inventoryExit.quantity = Number(this.editedItem.exitQuantity)
      inventoryExit.cost = Number(this.editedItem.exitProduct.cost * inventoryExit.quantity)
      inventoryExit.docs = []
      inventoryExit.user = this.getUser?.uid || null
      const inventoryEntry = {}
      // inventory.id = this.inventories.length + 1
      inventoryEntry.date = new Date().getTime()
      inventoryEntry.company = this.getCompany.identification
      inventoryEntry.product = this.editedItem.entryProduct.code
      // inventoryEntry.productFull = this.getProductTmp
      inventoryEntry.detail = 'ENTRADA POR FABRICACION'
      inventoryEntry.entry = this.editedItem.entry
      inventoryEntry.exit = null
      inventoryEntry.quantity = Number(this.editedItem.entryQuantity)
      inventoryEntry.cost = inventoryExit.cost
      inventoryEntry.docs = []
      inventoryEntry.user = this.getUser?.uid || null
      console.log(inventoryExit, inventoryEntry)
      stack.push(this.insertInventory(inventoryEntry))
      Promise.all(stack)
        .then(() => {
          this.$emit('update')
          this.close()
        })
        .catch((e) => console.error(e))
    },
    ...mapActions('inventories', ['insertInventory'])
  },
  computed: {
    cellarItemsExit () {
      const cellars = this.cellarItems.filter(({ value }) => this.editedItem.exitProduct?.stocks[value] > 0)
      return cellars
    },
    cellarItems () {
      const cellars = this.getCellars.map((cellar) => ({ text: cellar.name, value: cellar.code }))
      return cellars
    },
    getItems () {
      const products = this.products.filter(({ inventoryable }) => inventoryable)
      return products.map((product) => {
        const cost = Number(product.entriesValue || 0) / Number(product.entries || 1)
        const stock = Object.entries(product.stocks).reduce((acc, cur) => acc + cur[1], 0)
        const initialInventory = Number(product.initialInventory || 0)
        const stockTotal = Number(stock || 0) + initialInventory
        return {
          product,
          code: product.commercialCode,
          entries: 0,
          exits: 0,
          cost,
          value: cost,
          currentvalue: stockTotal * cost,
          stock: stockTotal,
          stocks: product.stocks
        }
      })
    },
    ...mapGetters('company', ['getCompany']),
    ...mapGetters('user', [
      'getUser'
    ]),
    ...mapGetters('inventories', [
      'getCellars'
    ])
  }
}
</script>
