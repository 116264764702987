<template>
  <div>
    <div class="d-flex mt-5 mb-0">
      <div class="px-2">
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          dense
          solo
          flat
        ></v-text-field>
      </div>

      <div class="px-2">
        <v-select
          :items="statusItems"
          label="Estado"
          v-model="status"
          dense
          solo
          flat
        ></v-select>
      </div>

      <div class="px-2  mr-auto" v-if="false">
        <v-select
          label="Solo field"
          dense
          solo
          flat
        ></v-select>
      </div>

      <div class="px-2" v-if="false">
        <v-select
          label="Solo field"
          dense
          solo
          flat
        ></v-select>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="num"
      class="elevation-1"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      hide-default-footer
      :loading="loading"
      sort-desc
    >
      <template v-slot:footer="{ props }">
        <div class="d-flex justify-space-between pa-6 caption">
          <div class="caption">Mostrando <v-select
            style="display: inline-block; width: 68px;"
              v-model="itemsPerPage"
              outlined
              dense
              class="caption"
              append-icon="mdi-chevron-down"
              :items="[
                { text: '5', value: 5 },
                { text: '10', value: 10 },
                { text: '15', value: 15 },
                { text: 'Todas', value: props.pagination.itemsLength },
              ]"
            >
              <template v-slot:selection="{ item }">{{ item.value }}</template>
            </v-select> de {{ props.pagination.itemsLength }}
          </div>
          <v-pagination
            v-model="page"
            :length="props.pagination.pageCount"
          ></v-pagination>
        </div>
      </template>
      <template v-slot:item.deadline="{ item }">
        <div
          :class="`ma-1 ${colorDate(item.task)}--text`"
          v-if="(item.task && item.task instanceof Object)"
        >
          {{ item.task.deadline | dateFormat(true) }}
        </div>
      </template>
      <template v-slot:item.product="{ item }">
        {{ item.commercialCode }}: {{ item.product.description }}
      </template>
      <template v-slot:item.status="{ item }">
        <div
          :class="`ma-1 ${statusObj[item.task.status].color}--text`"
          v-if="(item.task && item.task instanceof Object)"
        >
        {{ statusObj[item.task.status].text }}
        </div>
      </template>
      <template v-slot:item.created="{ item }">
        {{ item.created | dateFormat }}
      </template>
      <template v-slot:item.actions="{ item }">
        <PODialog v-if="(item.task && item.task instanceof Object)" :item="item" @updated="updateTask($event)"></PODialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import redaApp from '../api/redaApp'
import PODialog from '@/components/PODialog.vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'ProductionOrders',
  props: {
    productionOrders: Array,
    products: Array
    // Aquí es donde se declararían las propiedades de tu componente
  },
  components: {
    PODialog
  },
  data () {
    return {
      loading: false,
      dialog: false,
      status: null,
      search: null,
      itemsPerPage: 10,
      page: 1,
      statusItems: [
        { text: 'Atrasada', value: '01' },
        { text: 'En peligro', value: '02' },
        { text: 'Pendiente', value: '03' },
        { text: 'Iniciada', value: '04' },
        { text: 'Lista', value: '05' },
        { text: 'Entregada', value: '06' },
        { text: 'Todas', value: null }
      ],
      item: {
        product: null,
        quantity: 1,
        drm: [],
        task: null,
        customization: null,
        others: null,
        cc: null,
        orders: []
      },
      defaultItem: {
        product: null,
        quantity: 1,
        drm: [],
        task: null,
        customization: null,
        others: null,
        cc: null,
        orders: []
      },
      statusObj: {
        '01': { text: 'Atrasada', color: 'error' },
        '02': { text: 'En peligro', color: 'warning' },
        '03': { text: 'Pendiente', color: 'warning' },
        '04': { text: 'Iniciada', color: 'info' },
        '05': { text: 'Lista', color: 'primary' },
        '06': { text: 'Entregada', color: 'success' }
      }
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    headers () {
      return [
        { text: 'Código', value: 'num' },
        { text: 'Producto', value: 'product' },
        { text: 'Tiempo restante', value: 'deadline' },
        { text: 'Estado', value: 'status' },
        { text: 'Siguiente estación', value: 'nextStep' },
        { text: 'Acción', value: 'actions' }
      ]
    },
    items () {
      const items = JSON.parse(JSON.stringify(this.productionOrders))
      // eslint-disable-next-line no-unused-expressions
      items?.sort((a, b) => a.created - b.created)
      return items.map((item, i) => ({ ...item, num: i + 1 }))
        .filter(({ task }) => (task?.status === this.status || !this.status)) || []
    },
    form () {
      return {
        inputs: [
          {
            id: '001',
            label: 'Producto',
            name: 'product',
            value: 'product',
            type: 'autocomplete',
            items: this.products,
            itemValue: 'commercialCode',
            itemText: 'description',
            rules: 'required',
            cols: 8
          },
          {
            id: '002',
            label: 'Cantidad',
            name: 'quantity',
            value: 'quantity',
            type: 'number',
            cols: 4
          },
          // {
          //   id: '003',
          //   label: 'Materia prima directa',
          //   name: 'drm',
          //   value: 'drm',
          //   type: 'table',
          //   cols: 12
          // },
          {
            id: '005',
            label: 'Observaciones',
            name: 'others',
            value: 'others',
            type: 'textarea',
            cols: 12
          },
          {
            id: '004',
            label: 'Departamento',
            name: 'cc',
            value: 'cc',
            type: 'select',
            itemText: 'name',
            itemValue: 'code',
            items: this.getCostCenters.filter(({ type }) => type === 'CCP'),
            cols: 12
          }
        ]
      }
    },
    ...mapGetters('user', ['getUser']),
    ...mapGetters('siinco', ['getCostCenters'])
    // Aquí es donde se declararían las funciones computadas de tu componente
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    async updateTask (po) {
      this.loading = true
      const i = this.productionOrders.findIndex(({ uid }) => uid === po.uid)
      if (i < 0) return
      const task = await redaApp.getTask(this.productionOrders[i].task.uid)
      const j = task.childs
        .findIndex(({ status }) => status === '03')
      this.productionOrders[i].nextStep = task.childs[j + 1] || null
      this.productionOrders[i].currStep = task.childs[j] || task
      this.productionOrders[i].task = task
      this.loading = false
    },
    close () {
      this.dialog = false
      this.$emit('close')
    },
    colorDate (task) {
      if (!task.deadline || task.status === '05') return 'black'
      const time = Number(task.deadline) - new Date().getTime()
      const day = 86400000
      if (Math.abs(time) < day) return 'error'
      if (Math.abs(time) < (day * 2)) return 'warning'
      return 'success'
    },
    save () {
      this.loading = true
      const newItem = { ...this.item }
      if (!this.item?.uid) {
        newItem.created = Number(moment().format('x'))
        newItem.createdBy = this.getUser.uid
      }
      redaApp.createProductionOrder(newItem).then((po) => {
        this.$emit('submit')
        this.showSuccess('Orden creada')
        this.reset()
      })
        .catch((e) => {
          this.showError('Error al crear orden de producción')
          console.error(e)
        })
        .finally(() => { this.loading = false })
    },
    reset () {
      if (this.po) {
        this.item.product = this.po.product || null
        this.item.quantity = this.po.quantity || 1
        this.item.drm = this.po.drm?.type || []
        this.item.task = this.po.task || null
        this.item.customization = this.po.customization || []
        this.item.others = this.po.others || null
        this.item.cc = this.po.cc || null
        this.item.orders = this.po.orders || null
      } else {
        this.item = { ...this.defaultItem }
      }
      this.close()
    },
    ...mapActions('message', ['showError', 'showSuccess'])
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
