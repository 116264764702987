<template>
  <div class="datos-del-cliente">
        <div class="frame-parent">
            <div class="datos-del-cliente-wrapper">
                <div class="datos-del-cliente1">Datos del cliente</div>
            </div>
            <div class="frame-group">
                <div class="frame-container">
                    <div class="cedula-parent">
                        <div class="cedula">Cedula</div>
                        <div class="div">*</div>
                    </div>
                    <div class="wrapper">
                        <div class="div1">0000000000000</div>
                    </div>
                </div>
                <div class="frame-div">
                    <div class="nombre-del-cliente-parent">
                        <div class="cedula">Nombre del cliente</div>
                        <div class="div">*</div>
                    </div>
                    <div class="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-wrapper">
                        <div class="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
                    </div>
                </div>
                <div class="frame-div">
                    <div class="autorizacin-wrapper">
                        <div class="cedula">Autorización</div>
                    </div>
                    <div class="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-wrapper">
                        <div class="div3">00000000000000</div>
                    </div>
                </div>
            </div>
            <div class="frame-parent2">
                <div class="frame-div">
                    <div class="nombre-del-cliente-parent">
                        <div class="cedula">Correo electronico</div>
                        <div class="div">*</div>
                    </div>
                    <div class="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-wrapper">
                        <div class="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
                    </div>
                </div>
                <div class="frame-div">
                    <div class="nombre-del-cliente-parent">
                        <div class="cedula">Teléfono</div>
                        <div class="div">*</div>
                    </div>
                    <div class="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-wrapper">
                        <div class="xxxxxxxxxxxxxx">xxxxxxxxxxxxxx</div>
                    </div>
                </div>
                <div class="frame-container">
                    <div class="cedula-parent">
                        <div class="cedula">Tipo de cliente</div>
                        <div class="div">*</div>
                    </div>
                    <div class="mayorista-parent">
                        <div class="mayorista">Mayorista</div>
                        <img class="icon-chevron" alt="" src="Icon / Chevron.svg">

                    </div>
                </div>
            </div>
            <div class="frame-container">
                <div class="direccin-wrapper">
                    <div class="cedula">Dirección</div>
                </div>
                <div class="frame-parent7">
                    <div class="provincia-parent">
                        <div class="mayorista">Provincia</div>
                        <img class="icon-chevron" alt="" src="Icon / Chevron.svg">

                    </div>
                    <div class="provincia-parent">
                        <div class="mayorista">Cantón</div>
                        <img class="icon-chevron" alt="" src="Icon / Chevron.svg">

                    </div>
                    <div class="provincia-parent">
                        <div class="mayorista">Distrito</div>
                        <img class="icon-chevron" alt="" src="Icon / Chevron.svg">

                    </div>
                </div>
            </div>
        </div>
        <div class="frame-group">
            <div class="frame-div">
                <div class="autorizacin-wrapper">
                    <div class="cedula">Nombre solicitante</div>
                </div>
                <div class="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-wrapper">
                    <div class="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
                    <img class="icon-chevron" alt="" src="Icon / Chevron.svg">

                </div>
            </div>
            <div class="frame-container">
                <div class="direccin-wrapper">
                    <div class="cedula">Correo electrónico</div>
                </div>
                <div class="frame">
                    <div class="div1">0000000000000</div>
                </div>
            </div>
            <div class="frame-container">
                <div class="direccin-wrapper">
                    <div class="cedula">Teléfono </div>
                </div>
                <div class="wrapper">
                    <div class="div1">0000000000000</div>
                </div>
            </div>
        </div>
        <div class="buttonbtnprimary-colorbtn-r">
            <div class="btn-text">Agregar solicitante</div>
        </div>
        <hr>

        <div class="buttonbtnprimary-colorbtn-r1">
            <div class="btn-text">Agregar cliente</div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'CustomerData',
  props: {
    // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    // Aquí es donde se declararían las funciones computadas de tu componente
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
hr {
  border: 1.6px solid #e5eaff;
          width: 100%;
          border-radius: 25px;
}
.datos-del-cliente1 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: 500;
}
.datos-del-cliente-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 20px;
  color: #480ca8;
  font-family: Rubik;
}
.cedula {
  position: relative;
  font-weight: 500;
}
.div {
  position: relative;
  font-weight: 500;
  color: #f72585;
}
.cedula-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.div1 {
  flex: 1;
  position: relative;
}
.wrapper {
  width: 128.7px;
  border-radius: 8px;
  border: 1px solid #4361ee;
  box-sizing: border-box;
  height: 22.7px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  font-size: 11px;
  color: #000;
}
.frame-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
}
.nombre-del-cliente-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx {
  width: 200.5px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-wrapper {
  border-radius: 8px;
  border: 1px solid #4361ee;
  box-sizing: border-box;
  height: 22.7px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 16px;
  font-size: 11px;
  color: #000;
}
.frame-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
}
.autorizacin-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.div3 {
  width: 86.7px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.frame-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}
.xxxxxxxxxxxxxx {
  width: 81.4px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.mayorista {
  width: 101.7px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.icon-chevron {
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.mayorista-parent {
  border-radius: 8px;
  border: 1px solid #4361ee;
  box-sizing: border-box;
  height: 22.7px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 6px;
  font-size: 11px;
  color: #000;
}
.frame-parent2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;
}
.direccin-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.provincia-parent {
  border-radius: 8px;
  border: 1px solid #4361ee;
  box-sizing: border-box;
  height: 22.7px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 6px;
}
.frame-parent7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;
  font-size: 11px;
  color: #000;
}
.frame-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.frame {
  width: 188px;
  border-radius: 8px;
  border: 1px solid #4361ee;
  box-sizing: border-box;
  height: 23px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  font-size: 11px;
  color: #000;
}
.btn-text {
  position: relative;
  letter-spacing: 0.12px;
  line-height: 12.66px;
  font-weight: 500;
}
.buttonbtnprimary-colorbtn-r {
  border-radius: 21.29px;
  background-color: #f72585;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  text-align: center;
  color: #fff;
}
.datos-del-cliente-child {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.buttonbtnprimary-colorbtn-r1 {
  border-radius: 21.29px;
  background-color: #f72585;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  text-align: center;
  color: #fff;
}
.datos-del-cliente {
  width: 100%;
  position: relative;
  border-radius: 20px;
  background-color: #fff;
  border: 1.5px solid #4361ee;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 32px 24px;
  gap: 24px;
  text-align: left;
  font-size: 12px;
  color: #3f37c9;
  font-family: Roboto;
}
</style>
