<template>
  <div class="productos">
    <div class="titulo-seccion">
        <div class="datos-del-cliente">Productos</div>
    </div>
    <div class="frame-parent">
        <div class="magebar-code-scan-parent">
            <div class="magebar-code-scan">
                <img class="vector-icon" alt="" src="../assets/Vector.svg">

            </div>
            <div class="caja-de-texto">
              <v-menu bottom offset-y :close-on-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <input
                    v-bind="attrs"
                    v-on="on"
                    type="text"
                    class="input-texto"
                    v-model="search"
                  >
                </template>
                <v-list>
                  <v-list-item-group v-model="group">
                    <v-list-item
                      v-for="(item, index) in items"
                      :key="index"
                      @click="product = item"
                    >
                      <v-list-item-title>{{ item.commercialCode }}: {{ item.description }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
            <img class="group-icon" alt="" src="../assets/Group.svg">
        </div>
        <div class="desplegables" v-if="false">
            <div class="desplegable">Desplegable</div>
            <img class="icon-chevron" alt="" src="Icon / Chevron.svg">
        </div>
        <div class="cantidad-parent">
            <div class="cantidad">Cantidad:</div>
            <div class="wrapper">
              <input type="number" class="input-texto-quantity" v-model="quantity">
            </div>
        </div>
    </div>
    <div class="frame-group" v-if="false">
        <div class="ancho-parent">
            <div class="cantidad">Ancho</div>
            <div class="container">
                <div class="div">2</div>
            </div>
            <div class="cantidad">m²</div>
        </div>
        <div class="ancho-parent">
            <div class="cantidad">Largo</div>
            <div class="container">
                <div class="div">2</div>
            </div>
            <div class="cantidad">m²</div>
        </div>
    </div>
    <hr style="height: 0px; align-self: stretch; stroke-width: 1.5px; stroke: var(--Lineas-de-seccion, #E5EAFF);">

    <div class="crea-tu-formulario" v-if="product && product.customization && product.customization.length > 0">Crea tu formulario de requerimientos</div>
    <div class="frame-container" v-if="false" >
        <div class="frame-wrapper">
            <div class="frame-div">
                <div class="pregunta-de-seleccin-selecci-wrapper">
                    <div class="cantidad">
                        <ol class="pregunta-de-seleccin-selecci">
                            <li>Pregunta de selección, seleccione la opción correcta </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="frame-parent1">
          <div class="ellipse-parent">
              <input type="radio" class="frame-child" id="option1" name="options">
              <label for="option1" class="opcin-1-wrapper">
                  <div class="aadir-opcin">Opción 1</div>
              </label>
          </div>
          <div class="ellipse-parent">
              <input type="radio" class="frame-child" id="option2" name="options">
              <label for="option2" class="opcin-1-wrapper">
                  <div class="aadir-opcin">Opción 2</div>
              </label>
          </div>
          <div class="ellipse-parent">
              <input type="radio" class="frame-child" id="option3" name="options">
              <label for="option3" class="opcin-1-wrapper">
                  <div class="aadir-opcin">Opción 3</div>
              </label>
        </div>
      </div>

    </div>
    <img class="productos-child" alt="" v-if="false" src="Line 9.svg">

    <div class="frame-container" v-if="false" >
        <div class="frame-wrapper">
            <div class="frame-div">
                <div class="pregunta-de-seleccin-selecci-wrapper">
                    <div class="cantidad">
                        <ol class="pregunta-de-seleccin-selecci">
                            <li>Check box, seleccione las opciones deseadas</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="frame-parent1">
          <div class="ellipse-parent">
            <input type="checkbox" class="rectangle-div">
            <div class="opcin-1-wrapper">
                <div class="aadir-opcin">Opción 1</div>
            </div>
          </div>
          <div class="ellipse-parent">
            <input type="checkbox" class="rectangle-div">
            <div class="opcin-1-wrapper">
                <div class="aadir-opcin">Opción 2</div>
            </div>
          </div>
          <div class="ellipse-parent">
            <input type="checkbox" class="rectangle-div">
            <div class="opcin-1-wrapper">
                <div class="aadir-opcin">Añadir opción</div>
            </div>
          </div>
        </div>
    </div>
    <img class="productos-child" alt="" v-if="false" src="Line 11.svg">

    <div class="frame-parent4">
        <div class="detalles-aparecen-en-factura-wrapper">
            <div class="cantidad">Detalles (aparecen en factura)</div>
        </div>
        <div class="frame-wrapper3">
          <textarea v-model="description" class="agregue-los-detalles-correspon-wrapper" rows="4" placeholder="Agregue los detalles correspondientes al producto"></textarea>
        </div>
    </div>
    <div class="frame-parent5">
        <div class="frame-parent6">
            <div class="pregunta-de-seleccin-selecci-wrapper">
                <div class="cantidad">Precio unitario:</div>
            </div>
            <div class="wrapper1">
              <input type="number" class="div3" v-model="unitPrice" />
            </div>
        </div>
        <div class="frame-parent6">
            <div class="pregunta-de-seleccin-selecci-wrapper">
                <div class="cantidad">Precio total (sin IVA):</div>
            </div>
            <div class="wrapper1">
              <input type="number" class="div3" readonly :value="subtotal" />
            </div>
        </div>
        <div class="frame-parent6">
            <div class="pregunta-de-seleccin-selecci-wrapper">
                <div class="cantidad">Descuento</div>
            </div>
            <div class="wrapper2">
              <input type="number" class="div3" v-model="discount" />
            </div>
            <div class="pregunta-de-seleccin-selecci-wrapper">
                <div class="cantidad">%</div>
            </div>
        </div>
        <div class="frame-parent6">
            <div class="pregunta-de-seleccin-selecci-wrapper">
                <div class="cantidad">Precio con descuento:</div>
            </div>
            <div class="wrapper2">
              <input type="number" class="div3" readonly :value="total" />
            </div>
        </div>
    </div>
    <div class="boton-prinicipal"  @click="insertItem">
        <img class="group-icon1" alt="" src="../assets/Group2.svg">

        <div class="btn-text">Agregar producto</div>
    </div>
    <img class="productos-child" alt="" src="Line 10.svg"  v-if="false">

    <div class="buttonbtnprimary-colorbtn-r-wrapper" v-if="false">
        <div class="buttonbtnprimary-colorbtn-r">
            <div class="btn-text1">Guardar</div>
        </div>
    </div>
</div>
</template>
<script>
import firebaseApi from '../api/firebaseApi'
import { mapGetters } from 'vuex'
function decimalAdjust (type, value, exp) {
  // Si el exp no está definido o es cero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value)
  }
  value = +value
  exp = +exp
  // Si el valor no es un número o el exp no es un entero...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN
  }
  // Shift
  value = value.toString().split('e')
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)))
  // Shift back
  value = value.toString().split('e')
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp))
}
function round10 (value, exp) {
  return decimalAdjust('round', value, exp)
}
export default {
  name: 'AddProduct',
  props: {
    // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      products: [],
      listenerProducts: null,
      search: null,
      product: null,
      group: null,
      unitPrice: 0,
      discount: 0,
      quantity: 1,
      description: null
    }
  },
  computed: {
    items () {
      return this.products.filter((item) => this.customFilter(item, this.search))
    },
    subtotal () {
      return this.unitPrice * Number(this.quantity)
    },
    total () {
      return this.subtotal - this.totalDiscount
    },
    totalDiscount  () {
      if (!Number(this.discount)) return 0
      return this.subtotal * (1 / (Number(this.discount) || 1))
    },
    ...mapGetters('company', [
      'getCompany'
    ])
  },
  watch: {
    product (val) {
      this.unitPrice = Number(val?.prices[0].price) || 0
      this.search = val?.description || null
      this.description = val?.description || null
    }
  },
  methods: {
    cleanItem () {
      this.search = null
      this.product = null
      this.group = null
      this.unitPrice = 0
      this.discount = 0
      this.quantity = 1
      this.description = null
    },
    insertItem: function () {
      if (this.product) {
        const newTaxes = {}
        const newItem = {}
        /* if (!this.checkStock({ code: this.tmp.item.code, quantity: round10(this.tmp.item.quantity, -5) })) {
          const errMsj = 'No hay suficientes productos codigo ' + this.tmp.item.code + ' en bodega.'
          this.showError(errMsj)
          return
        } /**/

        newItem.cabys = this.product.cabys
        newItem.commercialCode = this.product.commercialCode
        newItem.quantity = round10(this.quantity, -5)
        newItem.unit = this.product.unit
        newItem.description = this.description && this.description !== ''
          ? this.description : this.product.description
        newItem.price = { ...this.product.prices[0], price: this.unitPrice }
        newItem.discountRate = this.discount || 0
        // unitPrice: Number(this.product.unitPrice)
        // total: this.itemSubtotal,
        // subtotal: this.itemSubtotal,
        // taxable_base: this.itemSubtotal,
        // tax_net: this.itemTaxNet,
        // netTotal: this.itemNetTotal,

        if (this.product.taxes && this.product.taxes.length > 0) {
          newTaxes.code = this.product.taxes[0].code
          newTaxes.rateCode = this.product.taxes[0].rateCode
          newTaxes.rate = this.product.taxes[0].rate
          newItem.taxes = [newTaxes]
        }
        // this.invoice.items.push(newItem)
        this.newItem({ ...newItem })
        this.cleanItem()
      }
    },
    newItem (payload) {
      console.log({ ...payload })
      this.$store.dispatch('1/newItem', payload)
    },
    customFilter (item, queryText, itemTitle) {
      try {
        const textOne = item.description.toLowerCase()
        const textTwo = item.commercialCode.toString()
        const searchText = queryText?.toString().toLowerCase()

        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      } catch (error) {
        console.error(error)
        console.log({ ...item })
      }
    },
    reset () {
      if (this.listenerProducts) this.listenerProducts()
      // redaApp.importAttachmentsVouchers().then(() => console.log('Importing from email')).catch((e) => console.error(e))
      this.listenerProducts = firebaseApi.db.collection('products')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot(({ docs }) => {
          this.products = []
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            this.products.push({ ...doc.data(), uid: doc.id })
          }
        })
    }
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    this.reset()
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    this.listenerProducts()
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.datos-del-cliente {
    position: relative;
    line-height: 24px;
    font-weight: 500;
}
.titulo-seccion {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 0px;
    font-size: 24px;
    color: #480ca8;
    font-family: Rubik;
}
.vector-icon {
    width: 16.1px;
    position: relative;
    height: 16.1px;
}
.magebar-code-scan {
    width: 20.9px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2.1px;
    box-sizing: border-box;
}
.input-texto-quantity {
    width: 24px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: none;
    outline: none;
    background: transparent;
    text-align: center;
    font-size: 14px; /* Ajusta el tamaño de fuente si es necesario */
}
.input-texto {
    width: 214px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: none;
    outline: none;
    background: transparent;
    text-align: center;
    font-size: 14px; /* Ajusta el tamaño de fuente si es necesario */
}
.caja-de-texto {
    width: 230px;
    border-radius: 16px;
    border: 1px solid #4361ee;
    box-sizing: border-box;
    height: 21px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
}
.group-icon {
    width: 18.8px;
    position: relative;
    height: 18.8px;
}
.magebar-code-scan-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    text-align: center;
}
.desplegable {
    width: 62.3px;
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.icon-chevron {
    width: 10px;
    position: relative;
    height: 10px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: contain;
}
.desplegables {
    border-radius: 16px;
    border: 1px solid #4361ee;
    box-sizing: border-box;
    height: 21px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    gap: 6px;
}
.cantidad {
    position: relative;
    font-weight: 500;
}
.div {
    position: relative;
}
.wrapper {
    border-radius: 8px;
    border: 1px solid #4361ee;
    box-sizing: border-box;
    height: 22.7px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    font-size: 11px;
    color: #979797;
}
.cantidad-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    font-size: 12px;
    color: #535763;
}
.frame-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 20px;
    font-size: 11px;
    color: #979797;
}
.container {
    border-bottom: 1px solid #4361ee;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 8px;
    font-size: 11px;
    color: #979797;
}
.ancho-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}
.frame-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 20px;
    gap: 17px;
    font-size: 12px;
}
.productos-child {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}
.crea-tu-formulario {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    letter-spacing: 0.16px;
    line-height: 25.11px;
    font-weight: 500;
    font-family: Rubik;
}
.pregunta-de-seleccin-selecci {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    padding-left: 21px;
}
.pregunta-de-seleccin-selecci-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.frame-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.frame-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.custom-checkbox {
    width: 18.9px;
    height: 18.9px;
    position: relative;
    border-radius: 50%;
    border: 2.1px solid #4361ee;
    box-sizing: border-box;
    appearance: none; /* Oculta el estilo predeterminado del checkbox */
    cursor: pointer;
}
.custom-checkbox:checked {
    background-color: #4361ee; /* Color de fondo cuando está seleccionado */
}
.custom-radio {
    width: 18.9px;
    height: 18.9px;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    border: 2.1px solid #4361ee;
    box-sizing: border-box;
    cursor: pointer;
}

/* Ocultar el input radio original */
.custom-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* Estilo para cuando el radio button está seleccionado */
.custom-radio input[type="radio"]:checked + .custom-radio {
    background-color: #4361ee;
}
.frame-child {
    width: 18.9px;
    height: 18.9px;
    position: relative;
    border-radius: 50%;
    border: 2.1px solid #4361ee;
    box-sizing: border-box;
    appearance: none; /* Oculta el radio button original */
    cursor: pointer;
}

.frame-child:checked {
    background-color: #4361ee; /* Color de fondo cuando está seleccionado */
}

.opcin-1-wrapper {
    width: 243.3px;
    border-radius: 8.37px;
    background-color: #ebf1ff;
    height: 23.7px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6.3px 16.7px;
    box-sizing: border-box;
}
.ellipse-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
.frame-parent1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6.3px;
    font-size: 11px;
    color: #000;
}
.frame-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16.7px;
}
.rectangle-div {
    width: 18.8px;
    height: 18.8px;
    position: relative;
    border: 2.1px solid #4361ee;
    box-sizing: border-box;
    appearance: none; /* Remueve el estilo por defecto del checkbox */
    -webkit-appearance: none; /* Para compatibilidad con navegadores basados en WebKit */
    background-color: #fff; /* Fondo blanco para que parezca el div original */
    cursor: pointer; /* Añade un cursor de mano para simular el checkbox */
}

.rectangle-div:checked {
    background-color: #4361ee; /* Color de fondo al estar seleccionado */
}
.aadir-opcin {
    flex: 1;
    position: relative;
}
.detalles-aparecen-en-factura-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.agregue-los-detalles {
    margin: 0;
}
.agregue-los-detalles-correspon-wrapper {
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid #4361ee;
      padding: 6px 8px;
      display: block;
      resize: none; /* Evita que el usuario cambie el tamaño */
      font-family: inherit; /* Mantener la misma fuente */
      font-size: inherit; /* Mantener el tamaño de fuente */
}
.frame-wrapper3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 12px;
    color: #000;
}
.frame-parent4 {
    align-self: stretch;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
}
.div3 {
    width: 76.2px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: none;
    background-color: transparent;
    text-align: center;
    appearance: none; /* Elimina estilos por defecto de input */
    -moz-appearance: textfield; /* Elimina flechas en Firefox */
}

.div3::-webkit-inner-spin-button,
.div3::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Elimina flechas en WebKit */
    margin: 0;
}
.wrapper1 {
    border-bottom: 1.5px solid #4361ee;
    box-sizing: border-box;
    height: 22.7px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    text-align: center;
}
.frame-parent6 {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}
.wrapper2 {
    width: 43px;
    border-bottom: 1.5px solid #4361ee;
    box-sizing: border-box;
    height: 22px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    text-align: center;
}
.frame-parent5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    font-size: 12px;
    color: #535763;
}
.group-icon1 {
    width: 12.6px;
    position: relative;
    height: 12.6px;
}
.btn-text {
    position: relative;
    letter-spacing: 0.2px;
    line-height: 12.66px;
    font-weight: 500;
}
.boton-prinicipal {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25), 0px 4px 16px rgba(114, 9, 183, 0.1) inset;
    border-radius: 21.29px;
    background-color: #b5179e;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    gap: 6px;
    text-align: center;
    font-size: 11px;
    color: #fff;
    font-family: Rubik;
    cursor: pointer;
}
.btn-text1 {
    position: relative;
    letter-spacing: 0.12px;
    line-height: 13.24px;
    font-weight: 500;
}
.buttonbtnprimary-colorbtn-r {
    border-radius: 22.27px;
    background-color: #f72585;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8.4px 14.6px;
}
.buttonbtnprimary-colorbtn-r-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    font-size: 12.55px;
    color: #fff;
}
.productos {
    width: 100%;
    position: relative;
    border-radius: 20.92px;
    background-color: #fff;
    border: 1.5px solid #4361ee;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 25.1px 24px;
    gap: 25.1px;
    text-align: left;
    font-size: 16px;
    color: #3f37c9;
    font-family: Roboto;
}
</style>
