<template>
  <v-container>
    <h2>Ordenes de trabajo</h2>
    <orders-table :loading="loading" :tasks="tasks" @updatePO="updateTask($event)" :orders="orders"/>
  </v-container>
</template>

<script>
import firebaseApi from '../api/firebaseApi'
import redaApp from '../api/redaApp'
import { mapGetters } from 'vuex'
import OrdersTable from '@/components/OrdersTable'

export default {
  name: 'Orders',
  data: () => ({
    orders: [],
    customers: {},
    products: {},
    productionOrders: {},
    tasks: {},
    loading: true,
    listenerOrders: null
  }),
  components: {
    OrdersTable
  },
  mounted: async function () {
    this.customers = await redaApp.getIndexedItems('customers')
    this.products = await redaApp.getIndexedItems('products')
    this.productionOrders = await redaApp.getIndexedItems('productionOrders')
    this.reset()
  },
  beforeDestroy: function () {
    this.listenerOrders()
    console.log('beforeDestroy')
  },
  methods: {
    async updateTask (orders) {
      this.loading = true
      for (let k = 0; k < orders.length; k++) {
        const order = orders[k]
        const i = this.orders.findIndex(({ uid }) => uid === order)
        if (i < 0) continue
        const task = await redaApp.getTask(this.orders[i].task.uid)
        this.orders[i].task = task
      }
      this.loading = false
    },
    async reset () {
      if (this.listenerOrders) this.listenerOrders()
      this.listenerOrders = firebaseApi.db.collection('orders')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot((snapshot) => {
          this.loading = true
          const docs = snapshot.docChanges()
          for (let i = 0; i < docs.length; i++) {
            const { doc, type } = docs[i]
            const uid = doc.id
            if (type === 'removed') {
              const j = this.orders.findIndex((item) => item.uid === uid)
              this.orders.splice(j, 1)
            } else if (type === 'modified') {
              const j = this.orders.findIndex((item) => item.uid === uid)
              const order = doc.data()
              if (order.task) {
                redaApp.getTask(order.task)
                  .then((task) => {
                    order.task = task
                  })
                  .catch((e) => console.error(e))
              }
              const items = order.items.map((item) => ({ ...item, product: this.products[item.product] }))
              order.customer = this.customers[order.customer]
              order.items = items
              order.num = this.orders[j].num
              order.uid = uid
              this.orders[j] = order
            } else {
              const order = doc.data()
              if (order.task) {
                redaApp.getTask(order.task)
                  .then((task) => {
                    order.task = task
                    for (let i = 0; i < order.items.length; i++) {
                      const item = order.items[i]
                      if (item.po) {
                        const j = order.task.childs.findIndex(({ uid }) => uid === item.po.task)
                        order.items[i].po.task = order.task.childs[j]
                      }
                    }
                  })
                  .catch((e) => console.error(e))
              }
              const items = order.items.map((item) => {
                const po = item.po ? this.productionOrders[item.po] : null
                if (po) {
                  po.product = this.products[po.product]
                }
                return {
                  ...item,
                  product: this.products[item.product],
                  po
                }
              })
              order.customer = this.customers[order.customer]
              order.items = items
              order.num = docs.length - i
              order.uid = uid
              this.orders.push(order)
            }
          }
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters('company', [
      'getCompany'
    ])
  }
}
</script>
