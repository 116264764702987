var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mt-5 mb-0"},[_c('div',{staticClass:"px-2"},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","dense":"","solo":"","flat":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"px-2"},[_c('v-select',{attrs:{"items":_vm.statusItems,"label":"Estado","dense":"","solo":"","flat":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),(false)?_c('div',{staticClass:"px-2  mr-auto"},[_c('v-select',{attrs:{"label":"Solo field","dense":"","solo":"","flat":""}})],1):_vm._e(),(false)?_c('div',{staticClass:"px-2"},[_c('v-select',{attrs:{"label":"Solo field","dense":"","solo":"","flat":""}})],1):_vm._e()]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"num","items-per-page":_vm.itemsPerPage,"page":_vm.page,"hide-default-footer":"","loading":_vm.loading,"sort-desc":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"d-flex justify-space-between pa-6 caption"},[_c('div',{staticClass:"caption"},[_vm._v("Mostrando "),_c('v-select',{staticClass:"caption",staticStyle:{"display":"inline-block","width":"68px"},attrs:{"outlined":"","dense":"","append-icon":"mdi-chevron-down","items":[
              { text: '5', value: 5 },
              { text: '10', value: 10 },
              { text: '15', value: 15 },
              { text: 'Todas', value: props.pagination.itemsLength } ]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.value))]}}],null,true),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}}),_vm._v(" de "+_vm._s(props.pagination.itemsLength)+" ")],1),_c('v-pagination',{attrs:{"length":props.pagination.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]}},{key:"item.deadline",fn:function(ref){
            var item = ref.item;
return [((item.task && item.task instanceof Object))?_c('div',{class:("ma-1 " + (_vm.colorDate(item.task)) + "--text")},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.task.deadline,true))+" ")]):_vm._e()]}},{key:"item.product",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.commercialCode)+": "+_vm._s(item.product.description)+" ")]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [((item.task && item.task instanceof Object))?_c('div',{class:("ma-1 " + (_vm.statusObj[item.task.status].color) + "--text")},[_vm._v(" "+_vm._s(_vm.statusObj[item.task.status].text)+" ")]):_vm._e()]}},{key:"item.created",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [((item.task && item.task instanceof Object))?_c('PODialog',{attrs:{"item":item},on:{"updated":function($event){return _vm.updateTask($event)}}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }