<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="info"
          dark
          v-bind="attrs"
          v-on="on"
          text
        >
          Ver detalles
        </v-btn>
      </template>
      <POCard :item="item" @close="dialog = false" @updated="$emit('updated', $event); dialog = false"></POCard>
    </v-dialog>
  </div>
</template>
<script>
import POCard from '@/components/POCard.vue'
export default {
  name: 'PODialog',
  data () {
    return {
      dialog: false
    }
  },
  props: {
    item: Object
  },
  components: {
    POCard
  }
}
</script>
