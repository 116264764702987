<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="info"
          dark
          v-bind="attrs"
          v-on="on"
          :icon="icon"
          :text="!icon"
        >
          <v-icon v-if="icon">mdi-information</v-icon>
          <template v-else>Ver detalles</template>
        </v-btn>
      </template>
      <OrderCard :order="item" @updated="$emit('updatePO', $event)" @close="dialog = false"></OrderCard>
    </v-dialog>
  </div>
</template>
<script>
import OrderCard from '@/components/OrderCard.vue'
export default {
  name: 'OrderDialog',
  data () {
    return {
      dialog: false
    }
  },
  props: {
    item: Object,
    icon: Boolean
  },
  components: {
    OrderCard
  }
}
</script>
