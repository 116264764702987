<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      max-width="650"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <ProductionFormula :products="products" :item="item"></ProductionFormula>
    </v-dialog>
  </div>
</template>
<script>
import ProductionFormula from '@/components/ProductionFormula.vue'
export default {
  name: 'PFNewDialog',
  data () {
    return {
      dialog: false
    }
  },
  props: {
    item: Object,
    products: Array
  },
  components: {
    ProductionFormula
  }
}
</script>
