<template>
  <div class="text-center">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="item.status !== '05' ? 'grey' : 'success'"
          dark
          v-bind="attrs"
          v-on="on"
          @click="verify"
          icon
          :loading="loading"
        >
          <v-icon>
            {{ item.status !== '05' ? 'mdi-check-circle-outline' : 'mdi-check-circle' }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card
        :loading="loading"
      >
        <v-card-title>
          {{ title }}
        </v-card-title>

        <v-card-text v-if="!(dependencyPending.length > 0)">
          <v-list>
            <v-list-item
              v-for="({value, text}, i) in tmp"
              :key="i"
            >
              <v-list-item-action>
                <v-checkbox :input-value="value" @change="check(i, $event)"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-text v-else>
          <v-list>
            <v-subheader>Debe completar antes las siguientes tareas:</v-subheader>
            <v-list-item
              v-for="(dependency, i) in dependencyPending"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title>{{ dependency.name }}</v-list-item-title>
                <v-list-item-subtitle :class="`${statusObj[dependency.status].color}--text`">{{ statusObj[dependency.status].text }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-text v-if="childs.length > 0">
          <v-list>
            <v-subheader>Subtareas:</v-subheader>
            <v-list-item
              v-for="(child, i) in childs"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title>{{ child.name }}</v-list-item-title>
                <v-list-item-subtitle :class="`${statusObj[child.status].color}--text`">{{ statusObj[child.status].text }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="secondary"
            @click="save"
            :loading="loading"
            v-if="!(dependencyPending.length > 0)"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import redaApp from '../api/redaApp'
moment.locale('es')
export default {
  name: 'TaskActions',
  components: {},
  props: {
    item: Object,
    tasksIndexed: Object
  },
  data: () => ({
    dialog: false,
    loading: false,
    tmp: [],
    childs: [],
    statusObj: {
      '01': { text: 'En peligro', color: 'warning' },
      '02': { text: 'Atrasada', color: 'error' },
      '03': { text: 'Pendiente', color: 'grey' },
      '04': { text: 'Iniciada', color: 'info' },
      '05': { text: 'Lista', color: 'success' }
    }
  }),

  mounted () {
  },

  computed: {
    title () {
      return this.item.name
    },
    dependencyPending () {
      try {
        if (!this.item.dependency) return []
        if (typeof this.item.dependency === 'string') {
          const task = this.tasksIndexed[this.item.dependency]
          return task.status !== '05' ? [task] : []
        }
        const dependencyPending = this.item.dependency.map((depId) => {
          return this.tasksIndexed[depId]
        }).filter(({ status }) => status !== '05')
        return dependencyPending
      } catch (error) {
        console.log({ ...this.item }, this.item.dependency, !this.item.dependency)
        throw error
      }
    },
    tooltipText () {
      return this.item.todos && this.item.todos.length > 0
        ? 'Ver lista de verificación'
        : this.item.status === '05' ? 'Desmarcar' : 'Marcar como finalizada'
    },
    haveTodos () {
      return this.item.todos?.length > 0
    },
    ...mapGetters('company', [
      'getCompany'
    ])
  },

  watch: {
  },

  methods: {
    async verify () {
      this.loading = true
      const task = await redaApp.getTask(this.item.uid)
      this.childs = task.childs || []
      if (this.item.todos?.length > 0 || this.dependencyPending.length > 0 || this.childs.length > 0) {
        this.tmp = JSON.parse(JSON.stringify(this.item.todos || []))
        this.loading = false
        this.dialog = true
        return
      }
      this.save()
    },
    save () {
      this.loading = true
      const payload = this.haveTodos ? { todos: this.tmp } : { completed: Number(!(this.item.status === '05')) }
      redaApp.update('tasks', payload, this.item.uid).then(() => {
        this.showSuccess('Tarea actualizada')
        this.$emit('updated')
        this.dialog = false
      }).catch((e) => {
        console.error(e)
        this.showError('Error al actualizar tarea')
      }).finally(() => {
        this.tmp = []
        this.loading = false
      })
    },
    check (i, event) {
      this.tmp[i].value = event
    },
    ...mapActions('message', ['showError', 'showSuccess'])
  }
}
</script>
