<template>
  <v-card :loading="loading">
    <v-dialog v-model="dialogChange" max-width="600px">
      <v-card>
        <v-card-title class="text-center">
          <span class="headline"></span>
        </v-card-title>
        <v-card-text>
          <h1 class="display-2 text-center">Vuelto</h1>
          <h1 class="display-2 text-center">{{getChange}}</h1>
          <h1 class="display-1 text-center" v-if="currencyPay === 'USD'" >CRC: {{getChange * exchangeRate}}</h1>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogChange = false; cancel()" color="primary">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title v-if="voucher">
      <span class="headline">Pagar documento N° {{voucher.data.sequence}}</span>
    </v-card-title>
    <v-card-title v-else>
      <span class="headline">Registrar {{ exit ? 'Pago' : 'Cobro' }}</span>
    </v-card-title>
    <v-card-text>
      <v-container fluid v-if="bank">
      </v-container>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-select
              :items="[
                { text: 'Entrada de efectivo (cobro)', value: false },
                { text: 'Salida de efectivo (pago)', value: true },
              ]"
              label="Entrada/Salida de efectivo"
              outlined
              v-model="exit"
              dense
              v-if="bank"
            ></v-select>
            <p>Metodo de {{ exit ? 'Pago' : 'Cobro' }}</p>
            <v-btn-toggle v-model="paymentMethods" class="d-none d-sm-flex" mandatory>
              <v-btn text :value="item.value" v-for="(item, i) in itemsPaymentType" :key="i">
                {{ item.text }}
              </v-btn>
            </v-btn-toggle>
            <v-select
              :items="itemsPaymentType"
              v-model="paymentMethods"
              class="d-flex d-sm-none"
              ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <div
          v-if="!pos"
        >
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                :value="dateParse(date)"
                label="Fecha"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              :value="dateParse(date)"
              @change="setDate($event)"
            ></v-date-picker>
          </v-menu>
        </div>
        <v-text-field dense type="text" label="N° de referencia" v-model="transaction" outlined v-if="paymentMethods === '04'" ></v-text-field>
        <v-text-field dense type="text" label="Detalle" v-if="!voucher && !pos" v-model="others" outlined ></v-text-field>
        <v-text-field dense type="number" label="Monto" v-model="amount" @keyup.enter="save" outlined></v-text-field>
        <v-text-field dense type="number" label="Paga con" v-model="payWith" @keyup.enter="save" outlined v-if="paymentMethods === '01' && getCashDaily" v-show="!exit && !view" autofocus></v-text-field>
        <v-radio-group v-model="currencyPay" row>
          <v-radio label="CRC" value="CRC"></v-radio>
          <v-radio label="USD" value="USD"></v-radio>
        </v-radio-group>
      </v-container>
      <v-container v-if="getAmountOutstanding">
        <h1 class="text-center">Saldo Pendiente</h1>
        <p class="display-2 text-center">{{getAmountOutstanding - getPayloadsAmount | currencyFormat(currencyPay)}}</p>
      </v-container>
      <v-container v-if="!voucher && !pos">
        <v-select
          outlined
          :items="[
            {value: '01', text: 'Pago de comprobantes' },
            {value: '02', text: 'Otras transacciones' },
          ]"
          dense
          v-model="receiptsFrom"
        ></v-select>
        <VouchersList :items="itemsVouchers" v-model="vouchers" v-if="receiptsFrom === '01' && !pos"></VouchersList>
        <v-autocomplete
          outlined
          :items="getAccountsDetails"
          :label="`Cuenta a ${exit ? 'debitar' : 'acreditar'}`"
          item-text="name"
          v-model="account"
          item-value="id"
          dense
          v-else
        >
          <template v-slot:append-outer>
            <DialogAccounts
              class="mb-10"
              title
              :titleText="`Cuenta a ${exit ? 'debitar' : 'acreditar'}`"
              @input="setAccount($event)"
              activatable
            ></DialogAccounts>
          </template>
        </v-autocomplete>
      </v-container>
      <!--<v-container>
          <v-btn-toggle v-model="money" class="d-none d-sm-flex" mandatory>
            <v-btn text value="01">
              1000
            </v-btn>
            <v-btn text value="02">
              2000
            </v-btn>
            <v-btn text value="03">
              3000
            </v-btn>
            <v-btn text value="04">
              5000
            </v-btn>
            <v-btn text value="99">
              6000
            </v-btn>
          </v-btn-toggle>
      </v-container>-->
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="cancel" v-if="!view">Cerrar</v-btn>
      <v-btn
        color="primary"
        @click="save"
        :disabled="payIsValid"
        :loading="loading"
      >Guardar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, /* mapState, */ mapGetters } from 'vuex'
import moment from 'moment'
import tools from '@/api/tools.js'
import VouchersList from '@/components/VouchersList.vue'
import DialogAccounts from '@/components/DialogAccounts.vue'
import { salesVoucherTypes } from '@/assets/codesApp'
import printer from '@/api/printer.js'

export default {
  name: 'NewReceipt',

  components: {
    VouchersList,
    DialogAccounts
  },

  props: [
    'pos',
    'amountOutstanding',
    'voucher',
    'isPayment',
    'item',
    'bank',
    'view'
  ],

  data: () => ({
    dialogChange: false,
    uid: null,
    payWith: null,
    loading: false,
    paymentMethods: '01',
    receiptsFrom: '01',
    amount: 0,
    currencyPay: 'CRC',
    transaction: null,
    others: null,
    vouchers: [],
    menu: false,
    account: null,
    date: null,
    exit: false,
    payloads: []
  }),

  mounted () {
    console.log('mounted')
    if (this.amountOutstanding) this.amount = Number(this.amountOutstanding)
    if (!this.item) this.date = Number(moment().format('x'))
    if (this.item) {
      const getVouchers = this.exit ? this.getVouchersReceived : this.getVouchers
      const vouchers = this.item.vouchers && this.item.vouchers.length > 0
        ? getVouchers.filter(({ key }) => this.item.vouchers.find((vou) => vou.key === key))
        : []
      console.log(this.item.voucher)
      if (this.item.voucher) {
        const vou = this.getVouchers.find(({ key }) => key === this.item.voucher)
        vouchers.push(vou)
      }
      console.log([...vouchers])
      this.uid = this.item.uid || null
      this.paymentMethods = this.item.paymentType || '01'
      this.receiptsFrom = this.item.receiptsFrom || '01'
      this.amount = this.item.amount || 0
      this.currencyPay = this.item.currency || 'CRC'
      this.transaction = this.item.transaction || null
      this.others = this.item.others || null
      this.vouchers = vouchers
      this.account = this.item.account || null
      this.date = this.item.date || null
    }
  },

  computed: {
    balance () {
      return this.getAmountOutstanding - this.getPayloadsAmount
    },
    getChange: function () {
      return this.payloads.reduce((acc, { payWith, amount }) => {
        if (payWith - amount > 0) return payWith - amount
      }, 0)
    },
    voucherAmounts () {
      return this.vouchers.map(({ key, amountOutstanding }) => {
        const vou = this.getItemVou.find((v) => v.key === key)
        const amout = vou ? vou.amount : amountOutstanding
        return { key, amout }
      })
    },
    payIsValid () {
      if (this.pos) return Number(this.amount) <= 0 || Number(this.getAmountOutstanding) < Number(this.amount)
      if (this.voucher) return Number(this.amount) <= 0 || Number(this.getAmountOutstanding) < Number(this.amount)
      if (this.vouchers.length > 0) return Number(this.getAmountOutstanding) !== Number(this.amount)
      if (this.receiptsFrom === '02') return !this.account
      return true
    },
    getCurrency () {
      if (this.voucher) return this.voucher.data.summary.currency
      if (this.vouchers.length > 0) return this.currencyPay
      return 'CRC'
    },
    getItemVou () {
      if (!(this.item?.vouchers?.length > 0)) return []
      const items = []
      for (let index = 0; index < this.vouchers.length; index++) {
        const curr = this.vouchers[index]
        const itemVou = this.item.vouchers.find(({ key }) => curr.key === key)
        if (itemVou) {
          const payKey = this.exit ? 'payments' : 'receipts'
          const pay = curr[payKey]?.find(({ uid }) => this.uid === uid)
          if (pay) {
            const payAmount = Number(pay.amount)
            const payExchangeRate = Number(pay.exchangeRate)
            const amount = pay.currency === this.currencyPay ? payAmount
              : pay.currency === 'CRC'
                ? tools.round10(payAmount / this.getExchange[this.currencyPay], -5)
                : tools.round10(payAmount * payExchangeRate, -5)
            items.push({ key: curr.key, amount })
          }
        }
      }
      return items
    },
    getPayloadsAmount () {
      return this.payloads.reduce((acc, curr) => {
        const curCurrency = curr.currency
        const curExchangeRate = curr.exchangeRate
        const currValue = curCurrency === this.currencyPay ? curr.amount
          : curCurrency === 'CRC'
            ? tools.round10(curr.amount / this.getExchange[this.currencyPay], -5)
            : tools.round10(curr.amount * curExchangeRate, -5)
        acc += currValue
        return acc
      }, 0)
    },
    getAmountOutstanding () {
      if (!this.amountOutstanding && this.vouchers.length === 0) return 0
      const amountOutstanding = this.amountOutstanding || this.vouchers.reduce((acc, curr) => {
        const curCurrency = curr.data.summary.currency
        const curExchangeRate = curr.data.summary.exchangeRate
        const currAmountOutstanding = curCurrency === this.currencyPay ? curr.amountOutstanding
          : curCurrency === 'CRC'
            ? tools.round10(curr.amountOutstanding / this.getExchange[this.currencyPay], -5)
            : tools.round10(curr.amountOutstanding * curExchangeRate, -5)
        acc += currAmountOutstanding
        const vouAmount = this.getItemVou.find(({ key }) => curr.key === key)
        return vouAmount ? acc + vouAmount.amount : acc
      }, 0)
      const val1 = this.getCurrency === this.currencyPay
        ? amountOutstanding : this.getCurrency === 'CRC'
          ? tools.round10(amountOutstanding / this.getExchange[this.currencyPay], -5)
          : tools.round10(amountOutstanding * this.getExchange[this.currencyPay], -5)
      return val1
    },
    itemsVouchers () {
      const vouchers = this.exit
        ? this.getVouchersReceived.filter((acc, i) => acc.amountOutstanding > 0 && salesVoucherTypes[acc.data.documentType])
        : this.getVouchers.filter((acc, i) => acc.amountOutstanding > 0 && salesVoucherTypes[acc.data.documentType])
      return vouchers
    },
    itemsPaymentType () {
      const items = [
        { value: '01', text: 'Efectivo' },
        { value: '02', text: 'Tarjeta' },
        { value: '03', text: 'Cheque' },
        { value: '04', text: 'Transferencia' },
        { value: '99', text: 'Otros' }
      ]
      return items
    },
    ...mapGetters(['getExchange']),
    ...mapGetters('vouchers', ['getVouchers', 'getVouchersReceived']),
    ...mapGetters('company', ['getCompany']),
    ...mapGetters('accounting', ['getAccountsDetails']),
    ...mapGetters('cashDaily', ['getCashDaily'])
  },

  watch: {
    amountOutstanding (newVal, oldVal) {
      this.amount = Number(newVal)
    },
    voucher (newVal, oldVal) {
      this.amount = this.getAmountOutstanding
    },
    vouchers (newVal, oldVal) {
      if (!this.item) this.amount = this.getAmountOutstanding
    },
    item (newVal, oldVal) {
      const getVouchers = this.exit ? this.getVouchersReceived : this.getVouchers
      const vouchers = newVal?.vouchers && newVal?.vouchers.length > 0
        ? getVouchers.filter(({ key }) => newVal?.vouchers.find((vou) => vou.key === key))
        : []
      if (newVal.voucher) {
        const vou = this.getVouchers.find(({ key }) => key === newVal.voucher)
        vouchers.push(vou)
      }
      console.log([...vouchers])
      this.uid = newVal?.uid || null
      this.paymentMethods = newVal?.paymentType || '01'
      this.receiptsFrom = newVal?.receiptsFrom || '01'
      this.amount = newVal?.amount || 0
      this.currencyPay = newVal?.currency || 'CRC'
      this.transaction = newVal?.transaction || null
      this.others = newVal?.others || null
      this.vouchers = vouchers
      this.account = newVal?.account || null
      this.date = newVal?.date || Number(moment().format('x'))
    },
    isPayment (newVal, oldVal) {
      this.exit = newVal
    }
  },
  beforeDestroy: function () {
    this.cancel()
    console.log('Destroy')
  },

  methods: {
    setAccount (account) {
      this.account = account?.id
    },
    setDate (newDate) {
      this.menu = false
      this.date = Number(moment(newDate).format('x'))
    },
    dateParse (date) {
      return moment(date, 'x').format().substring(0, 10)
    },
    async save () {
      let exchangeRate = 1
      const receiver = this.exit ? this.voucher?.issuer : this.voucher?.receiver
      if (this.currencyPay !== 'CRC') {
        exchangeRate = this.getExchange[this.currencyPay]
      }
      const amount = this.voucher || this.vouchers?.length > 0 || this.pos ? (this.amount > this.balance
        ? this.getAmountOutstanding
        : this.payWith && this.payWith < this.amount ? Number(this.payWith) : Number(this.amount)) : Number(this.amount)
      const newReceipt = {
        payWith: this.payWith,
        id: null,
        receiptsFrom: this.receiptsFrom || '01',
        company: this.getCompany.identification,
        transaction: this.transaction || null,
        date: this.date,
        voucher: this.voucher ? this.voucher.key : null,
        receiver: receiver || null,
        amount,
        account: this.account || null,
        paymentType: this.paymentMethods,
        currency: this.currencyPay,
        exchangeRate,
        others: this.others,
        vouchers: this.vouchers.length > 0 ? this.voucherAmounts : null
      }
      if (this.uid) { newReceipt.uid = this.uid } else { newReceipt.user = this.getUser.uid }
      this.payloads.push(newReceipt)
      if (this.balance === 0 || (this.voucher?.data.condition !== '01' && !this.pos)) {
        if (this.pos) {
          this.$emit('payVoucher', [...this.payloads])
          if (this.getChange) { this.dialogChange = true } else { this.cancel() }
          this.loading = false
          return
        }
        this.loading = true
        const receipts = this.voucher?.receipts && this.voucher.receipts.length > 0 ? [...this.voucher.receipts] : []
        for (let index = 0; index < this.payloads.length; index++) {
          const { ...receipt } = this.payloads[index]
          try {
            await this.submitReceipt(receipt)
          } catch (error) {
            this.showError('¡El Pago no pudo ser realizado!')
            console.error(error)
            console.log(this.getUser)
            console.log(newReceipt)
          }
        }
        this.showSuccess('Pago Realizado')
        if (this.getCashDaily && this.voucher) printer.printVoucher(this.voucher.data, [...receipts.concat(this.payloads)])
        if (this.getChange) { this.dialogChange = true } else { this.cancel() }
        this.loading = false
      } else {
        this.reset()
        this.amount = this.balance
      }
    },
    submitReceipt (newReceipt) {
      delete newReceipt.payWith
      const insert = this.exit ? this.insertPayment : this.insertReceipt
      if (newReceipt.vouchers && newReceipt.vouchers.length === 1) {
        newReceipt.voucher = newReceipt.vouchers[0].key
        newReceipt.vouchers = null
      }
      return insert(newReceipt)
    },
    reset () {
      this.payWith = null
      this.loading = false
      this.paymentMethods = '01'
      this.amount = 0
      this.currencyPay = 'CRC'
      this.transaction = null
      this.menu = false
    },
    cancel () {
      this.reset()
      this.vouchers = []
      this.payloads = []
      this.$emit('cancel')
    },
    ...mapActions('message', ['showSuccess', 'showError']),
    ...mapActions('accountReceivable', ['insertReceipt']),
    ...mapActions('payments', ['insertPayment']),
    ...mapActions('user', ['getUser'])
  }
}
</script>
