<template>
  <div class="producto-parent">
    <div class="producto">{{ item.product.description | capitalizeWords }}</div>
    <div class="cdigo-de-orden">{{ item.num }}</div>
    <hr />

    <div class="informacin-formulario-de-prod-parent">
      <div class="informacin-formulario-de-prod" :key="i" v-for="(input, i) in item.customization">
        <a class="informacin-formulario-de-prod5" :href="input.value" v-if="isURL(input.value)">
          <img class="octiconupload-16" alt="" src="..\assets\Upload.svg">

          <div class="accin-subir-archivo">{{ input.text }}</div>
        </a>
        <div class="informacin-formulario-de" v-else>{{ `${input.text}: ${input.value}` }}</div>
      </div>
      <div class="informacin-formulario-de-prod5">
          <img class="octiconupload-16" alt="" src="..\assets\Upload.svg">

          <div class="accin-subir-archivo">Acción: Subir Archivo Adjunto</div>
      </div>
      <div class="informacin-formulario-de-prod5">
          <img class="lsiconmanagement-outline" alt="" src="..\assets\Upload.svg">

          <div class="accin-subir-archivo">Acción: Descargar Archivo Adjunto</div>
      </div>
    </div>
    <hr />

    <div class="informacin-formulario-de-prod-parent">
      <div class="informacin-formulario-de-prod7">
          <img class="lsiconmanagement-outline" alt="" src="..\assets\Materiales.svg">

          <div class="accin-subir-archivo">control de materiales empleados</div>
      </div>
      <div class="informacin-formulario-de-prod7">
          <img class="lsiconmanagement-outline" alt="" src="..\assets\Comments.svg">

          <div class="accin-subir-archivo">Agregar comentarios</div>
      </div>
    </div>
    <hr />

    <v-progress-circular
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-circular>

    <template v-if="item.task.status !== '05' && !loading && !orderCard">
      <div class="property-1next" v-if="item.nextStep" @click="save">
        <div class="enviar-a-la">Siguiente estación</div>
      </div>

      <div class="place-order-button" v-else  @click="save">
        <div class="marcar-como-terminado">Marcar como terminado</div>
      </div>
    </template>
  </div>
</template>
<script>
import redaApp from '../api/redaApp'
import { mapActions } from 'vuex'
export default {
  name: 'POCard',
  props: {
    item: Object,
    orderCard: Boolean
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    currStep () {
      if (this.item.currStep) return this.item.currStep
      const task = this.item.task
      const j = this.item.task.childs?.findIndex(({ status }) => status === '03')
      if (!j) return task
      const currStep = this.item.task.childs[j] || task
      return currStep
    }
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    isURL (str) {
      try {
        const url = new URL(str) // La asignación evita el error de "no-new"
        return url.protocol === 'http:' || url.protocol === 'https:'
      } catch {
        return false
      }
    },
    save () {
      this.loading = true
      const payload = { completed: 1 }
      redaApp.update('tasks', payload, this.currStep?.uid).then(() => {
        this.showSuccess('Orden actualizada')
        this.$emit('updated', this.item)
      }).catch((e) => {
        console.error(e)
        this.showError('Error al actualizar orden')
      }).finally(() => {
        this.loading = false
      })
    },
    ...mapActions('message', ['showError', 'showSuccess'])
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.enviar-a-la {
  position: relative;
  font-weight: 500;
}
.property-1next {
  width: 100%;
  position: relative;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  background-color: #f72585;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 12px;
  box-sizing: border-box;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-family: Rubik;
  cursor: pointer;
}
hr {
  border: 1.5px solid #e5eaff;
  width: 100%;
  border-radius: 25px;

}
.producto {
  align-self: stretch;
  position: relative;
  font-size: 24px;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48.2px;
  flex-shrink: 0;
}
.cdigo-de-orden {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48.2px;
  flex-shrink: 0;
}
.frame-child {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.informacin-formulario-de {
  flex: 1;
  position: relative;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  text-transform: capitalize;
}
.informacin-formulario-de-prod {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.octiconupload-16 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.accin-subir-archivo {
  position: relative;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  text-transform: capitalize;
}
.informacin-formulario-de-prod5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.informacin-formulario-de-prod-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 8px;
  gap: 8px;
  text-align: left;
}
.lsiconmanagement-outline {
  width: 25px;
  position: relative;
  height: 25px;
  overflow: hidden;
  flex-shrink: 0;
}
.informacin-formulario-de-prod7 {
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0px;
  gap: 8px;
}
.marcar-como-terminado {
  position: relative;
  font-weight: 500;
}
.place-order-button {
  align-self: stretch;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  background-color: #4361ee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 12px;
  color: #fff;
  font-family: Rubik;
  cursor: pointer;
}
.producto-parent {
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 16px rgba(83, 87, 99, 0.05);
  border-radius: 24px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  gap: 12px;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-family: Roboto;
}
</style>
