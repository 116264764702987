<template>
<v-container>
  <v-data-table
    :headers="headers"
    :items="getProducts"
    sort-by="name"
    :search="search"
    class="elevation-1"
    dense
    :loading="loading"
    loading-text="Cargando... Por favor espere"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Productos</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          class="mt-3 py-2 mr-2"
        ></v-text-field>
        <ImportData name="products" humanName="productos"></ImportData>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" dark class="mb-2" v-on="on"><v-icon left>mdi-plus</v-icon>Nuevo</v-btn>
          </template>
          <v-card
            :loading="loading"
          >
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        label="CAByS*"
                        v-model="editedItem.cabys"
                        :items="cabys"
                        item-value="code"
                        item-text="description"
                        @change="insertCabys"
                        clearable
                        :filter="customFilter"
                      >
                        <template v-slot:prepend>
                          <v-btn color="secondary" fab small><v-icon left>mdi-magnify</v-icon></v-btn>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!--<v-text-field label="Detalle*" v-model="tmp.item.description" required></v-text-field>-->
                      <v-text-field label="Código*" v-model="editedItem.commercialCode" :hint="lastCode !== '' && 'Último código: ' + lastCode" required :rules="[codeExists]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!--<v-text-field label="Detalle*" v-model="tmp.item.description" required></v-text-field>-->
                      <v-text-field label="Código de barras" v-model="editedItem.barCode"></v-text-field>
                    </v-col>
                    <v-col cols="12"  sm="8">
                      <v-text-field label="Detalle*" v-model="editedItem.description" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                      :items="units"
                      label="Unidad*"
                      v-model="editedItem.unit"
                      required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-checkbox v-model="taxed" :label="`IVA`"></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                      v-show="taxed"
                      :items="[
                      {value: '01', text: 'Tarifa 0% (Exento)' },
                      {value: '02', text: 'Tarifa reducida 1%' },
                      {value: '03', text: 'Tarifa reducida 2%' },
                      {value: '04', text: 'Tarifa reducida 4%' },
                      {value: '05', text: 'Transitorio 0%' },
                      {value: '06', text: 'Transitorio 4%' },
                      {value: '07', text: 'Transitorio 8%' },
                      {value: '08', text: 'Tarifa general 13%' }
                      ]"
                      label="Tarifa Impuesto Valor Agregado"
                      v-model="tmpTax.rateCode"
                      required
                      @change="setRate"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-checkbox v-model="editedItem.inventoryable" :label="`Llevar inventario`"></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field type="number" label="Inventario inicial" v-model="editedItem.initialInventory" v-if="editedItem.inventoryable"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field type="number" label="Costo inicial" v-model="editedItem.initialCost" v-if="editedItem.inventoryable"></v-text-field>
                    </v-col>
                  </v-row>
                  <prices></prices>
                  <p class="error--text">{{ errorMessagePrices }}</p>
                  <!--<v-row>
                    <v-col cols="12">
                      <h3>Total IVA: {{ itemTaxNet }}</h3>
                      <h3>Total Linea: {{ itemNetTotal }}</h3>
                    </v-col>
                  </v-row>-->
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn text @click="close" :disabled="loading">Cancelar</v-btn>
              <v-btn color="primary" @click="save" :disabled="loading">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="$router.push('/product/' + item.commercialCode)"
      >
        mdi-information
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:item.taxes="{ item }">
      {{item.taxes && item.taxes.length ? Number(item.taxes[0].rate) : 0}}
    </template>
    <template v-slot:item.price="{ item }">
      {{ item.price | currencyFormat((item.prices.findIndex( element => {return element.priceList == priceList}) > -1) ? item.prices[item.prices.findIndex( element => {return element.priceList == priceList})].currency : null)}}
    </template>
    <template v-slot:item.currency="{ item }">
      {{(item.prices.findIndex( element => {return element.priceList == priceList}) > -1) ? item.prices[item.prices.findIndex( element => {return element.priceList == priceList})].currency : "No definida"}}
    </template>
  </v-data-table>
</v-container>
</template>
<script>
import * as cabys from '@/assets/cabys.json'
import Prices from '@/components/Prices.vue'
import ImportData from '@/components/ImportData.vue'
import { mapGetters, mapActions } from 'vuex'
import { units } from '@/assets/codes.js'
import tools from '@/api/tools.js'
import firebaseApi from '../api/firebaseApi'
export default {
  name: 'Products',
  components: { Prices, ImportData },
  data: () => ({
    listenerProducts: null,
    products: [],
    errorMessagePrices: null,
    search: '',
    dialog: false,
    loading: false,
    priceList: 'general',
    units: units,
    headers: [
      { text: 'Código', value: 'commercialCode' },
      { text: 'Descripción', value: 'description' },
      { text: 'Unidad', value: 'unit' },
      { text: 'Moneda', value: 'currency' },
      { text: 'Precio', value: 'price', align: 'end' },
      { text: 'IVA %', value: 'taxes', align: 'end' },
      { text: 'Acciones', value: 'action', sortable: false }
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      initialCost: 0,
      initialInventory: 0,
      barCode: null,
      company: null,
      commercialCode: null,
      cabys: null,
      unit: 'Unid',
      description: null,
      inventoryable: false,
      prices: [],
      taxes: []
    },
    defaultItem: {
      initialCost: 0,
      initialInventory: 0,
      barCode: null,
      company: null,
      commercialCode: null,
      cabys: null,
      unit: 'Unid',
      description: null,
      inventoryable: false,
      prices: [],
      taxes: []
    },
    taxed: false,
    tmpTax: {
      code: '01',
      rateCode: '08',
      rate: 13
    },
    rateCodeValues: {
      '01': 0,
      '02': 1,
      '03': 2,
      '04': 4,
      '05': 0,
      '06': 4,
      '07': 8,
      '08': 13
    },
    rateCodeText: {
      0: '01',
      1: '02',
      2: '03',
      4: '04',
      8: '07',
      13: '08'
    },
    cabys: cabys.default.data
  }),
  mounted: function () {
    this.reset()
  },
  beforeDestroy: function () {
    this.listenerProducts()
  },
  computed: {
    getProducts () {
      return this.products
    },
    lastCode () {
      const code = this.editedItem.commercialCode
      const codes = this.products.filter(({ commercialCode }) => {
        return commercialCode.indexOf(code) > -1
      }).map(({ commercialCode }) => commercialCode).sort()
      if (!(codes.length > 0)) return ''
      return codes[codes.length - 1]
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Producto' : 'Editar Producto'
    },
    ...mapGetters('priceList', [
      'getPrices'
    ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },
  filters: {
    round10 (value) {
      if (!value) return 0
      tools.round10(value, -5)
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    codeExists (value) {
      return !this.products?.find(({ commercialCode }) => commercialCode === value) || 'El código ya existe'
    },
    customFilter (item, queryText, itemTitle) {
      try {
        const textOne = item.description.toLowerCase()
        const textTwo = item.code.toString()
        const searchText = queryText.toString().toLowerCase()

        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      } catch (error) {
        console.error(error)
        console.log({ ...item })
      }
    },
    reset () {
      if (this.listenerProducts) this.listenerProducts()
      // redaApp.importAttachmentsVouchers().then(() => console.log('Importing from email')).catch((e) => console.error(e))
      this.listenerProducts = firebaseApi.db.collection('products')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot(({ docs }) => {
          this.products = []
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            const item = doc.data()
            const price = (item.prices.findIndex(element => { return element.priceList === this.priceList }) > -1)
              ? Number(
                item.prices[item.prices.findIndex(element => { return element.priceList === this.priceList })].price
              ) + (
                item.prices[item.prices.findIndex(element => { return element.priceList === this.priceList })].price *
            (item.taxes && item.taxes.length > 0 ? item.taxes[0].rate / 100 : 0)
              )
              : 'No definido'
            this.products.push({ ...item, uid: doc.id, price })
          }
        })
    },
    insertCabys () {
      if (!this.editedItem.cabys) {
        this.tmpTax = {
          code: '01',
          rateCode: '08',
          rate: 13
        }
        this.taxed = false
      } else {
        const cabys = this.cabys.find((item) => {
          return item.code === this.editedItem.cabys
        })
        this.tmpTax = {
          code: '01',
          rateCode: this.rateCodeText[cabys.iva],
          rate: Number(cabys.iva)
        }
        this.taxed = true
      }
    },
    setRate () {
      this.tmpTax.rate = this.rateCodeValues[this.tmpTax.rateCode]
      console.log(this.tmpTax)
    },
    newTax () {
      this.editedItem.taxes.push(this.tmpTax)
    },
    editItem (item) {
      this.editedIndex = this.getProducts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.taxed = !!item.taxes[0]
      this.setPrices(this.editedItem.prices)
      this.dialog = true
    },

    deleteItem (item) {
      this.loading = true
      this.deleteProduct(item)
        .then((msj) => {
          console.log(msj)
        })
        .catch((error) => {
          this.showError('El producto no puede ser eliminado')
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },

    close () {
      this.dialog = false
      this.taxed = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.tmpTax = Object.assign({}, { code: '01', rateCode: '08', rate: 13 })
        this.cleanPrices()
        this.editedIndex = -1
      }, 300)
    },

    save () {
      if (this.getPrices.length <= 0) {
        this.errorMessagePrices = 'Debe agregar al menos un precio'
        return
      }
      this.loading = true
      if (this.taxed) { this.newTax() }
      this.editedItem.prices = this.getPrices
      this.editedItem.company = this.getCompany.identification
      this.insertProduct(this.editedItem)
      /* .then( product => {
        if (product.inventoryable) {
          let alias = {
            company: this.getCompany.identification,
            code: product.commercialCode,
            factor: 1,
            product: product.commercialCode,
          };
          return this.insertInventoryAliases(alias);
        }
      }) /* */
        .then(() => {
          this.loading = false
          this.close()
        })
        .catch((error) => {
          console.log(error)
          this.showError('Ha ocurrido un error')
        })
      // var companiesRef;
      /* db.collection("companies").where("identification", "==", this.getCompany.identification).get()
      .then(querySnapshot => {
          querySnapshot.forEach(doc => {
              // doc.data() is never undefined for query doc snapshots
              let companiesRef = doc.ref;
            if (this.editedIndex > -1) {
              companiesRef.update({
                customers: fb.firestore.FieldValue.arrayRemove(this.getCompany.customers[this.editedIndex])
              });
              companiesRef.update({
                customers: fb.firestore.FieldValue.arrayUnion(this.editedItem)
              });
              //Object.assign(this.items[this.editedIndex], this.editedItem)
            } else {
              companiesRef.update({
                customers: fb.firestore.FieldValue.arrayUnion(this.editedItem)
              });
              //this.items.push(this.editedItem)
              //console.log(db.FieldValue)
            }
            this.close()
          });
      }); */
    },
    ...mapActions('priceList', ['cleanPrices', 'setPrices']),
    ...mapActions('message', ['showError']),
    ...mapActions('products', ['insertProduct', 'deleteProduct']),
    ...mapActions('inventories', ['insertInventoryAliases'])
  }
}
</script>
<style type="text/css">

</style>
