<template>
  <div class="d-flex" >
    <div style="width: calc(100% - 520px); text-align: center;"><NewOrderMain></NewOrderMain></div>
    <div style="width: 520px;"><PlaceOrder></PlaceOrder></div>
  </div>
</template>
<script>
// import { mapGetters, mapActions, mapMutations } from 'vuex'
// import { db, fb } from '@/api/firebase.js'
import NewOrderMain from '@/components/NewOrderMain.vue'
import PlaceOrder from '../components/PlaceOrder.vue'
// import AccountReceivable from '@/components/AccountReceivable.vue'

export default {
  name: 'NewOrder',
  components: { NewOrderMain, PlaceOrder },
  data: () => ({
  }),

  computed: {
    // ...mapGetters()
  },

  mounted () {
    this.setVoucherType('13')
  },

  watch: {
  },

  /* created () {
  }, */

  methods: {
    setVoucherType (payload) {
      this.$store.dispatch('1/setVoucherType', payload)
    }
    // ...mapMutations(),
    // ...mapActions()
  }
}
</script>
<style scoped>
</style>
