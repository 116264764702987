<template>
  <v-card>
    <v-card-text class="text-center pt-9">
      <v-sheet
        color="#560BAD80"
        height="366"
        width="765"
        rounded="xl"
        class="mx-auto"
      ></v-sheet>
    </v-card-text>
    <v-card-title class="text-center">
      🛠 Soporte al Cliente
    </v-card-title>
    <v-card-text class="text-center">
      No estás solo. Contacta a nuestro soporte al +506 8584 0347, envíanos un mensaje por WhatsApp, o solicita que te devolvamos la llamada. ¡Estamos aquí para ti!
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import moment from 'moment'
// import { /* mapActions, */ mapGetters } from 'vuex'
// import firebaseApi from '../api/firebaseApi'
moment.locale('es')
export default {
  name: 'SupportCard',
  components: {},
  props: {
  },
  data: () => ({
  }),

  mounted () {
  },

  computed: {
  },

  watch: {
  },

  methods: {
    emitUpdated () {
      this.$emit('updated')
    }
    // ...mapActions('inventories', ['insertInventory'])

  }
}
</script>
